import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector) // Erkennt die Browsersprache
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          welcomeText: `  <p style={{ lineHeight: 1.6 }}>
                    I have been working as a frontend developer since 2022.
                    <br />
                    I'm located in Germany, Bavaria.
                    <br></br>I spent most of my time working in the{" "}
                    <span style={{ color: "#2091a5" }}>
                      <b>
                        B2B or media sector, <br /> recently also in the
                        insurance industry as a frontend developer.
                      </b>
                    </span>
                  </p>`,
        },
      },
      de: {
        translation: {
          welcomeText: `<p style={{ lineHeight: 1.6 }}>
                    Ich arbeite seit 2022 als Frontend-Entwickler.
                    <br />
                   Ich lebe in Bayern, Deutschland.
                    <br></br> Die meiste Zeit habe ich im{" "}
                    <span style={{ color: "#2091a5" }}>
                      <b>
                        B2B- oder Mediensektor gearbeitet, <br /> kürzlich auch in der Versicherungsbranche als Frontend-Entwickler..
                      </b>
                    </span>
                  </p>`,
        },
      },
    },
    fallbackLng: "de", // Falls Sprache nicht erkannt wird, standardmäßig Englisch
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
