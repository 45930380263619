import { useEffect } from "react";
import $ from "jquery";
import colorSharp from "../assets/img/skills-bg.png";

export const Timeline = () => {
  useEffect(() => {
    $(".step").click(function () {
      $(this).addClass("active").prevAll().addClass("active");
      $(this).nextAll().removeClass("active");
    });

    $(".step01").click(function () {
      $("#line-progress").css("width", "3%");
      $(".discovery").addClass("active").siblings().removeClass("active");
    });

    $(".step02").click(function () {
      $("#line-progress").css("width", "33%");
      $(".strategy").addClass("active").siblings().removeClass("active");
    });

    $(".step03").click(function () {
      $("#line-progress").css("width", "66%");
      $(".creative").addClass("active").siblings().removeClass("active");
    });

    $(".step04").click(function () {
      $("#line-progress").css("width", "100%");
      $(".production").addClass("active").siblings().removeClass("active");
    });
  }, []);

  return (
    <section className="timeline" id="timeline">
      <div className="timeline-headline">
        <h2>Career Timeline</h2>
      </div>
      <div className="timeline-container">
        <div class="process-wrapper">
          <div id="progress-bar-container">
            <div class="d-flex flex-row">
              <div
                style={{ position: "absolute", top: "-40px", left: "10%" }}
                class="progress-bar-icons"
              >
                <i class="bi bi-mortarboard step step01 active progress-bar-icons"></i>
              </div>
              <div class="step-inner step01" style={{ textAlign: "center" }}>
                <span>Apprenticeship software developer</span>
              </div>
              <div
                style={{ position: "absolute", top: "-43px", left: "34.5%" }}
              >
                <img
                  src={require("../assets/img/aracom.jpg")}
                  class="step step02 progress-bar-image"
                  alt="aracom logo"
                  style={{ width: "40px", height: "40px" }}
                />
              </div>
              <div class="step-inner step02" style={{ textAlign: "center" }}>
                Junior software developer at AraCom{" "}
              </div>
              <div style={{ position: "absolute", top: "-45px", left: "61%" }}>
                <img
                  src={require("../assets/img/evy-logo.png")}
                  class="step step03 progress-bar-image"
                  alt="aracom logo"
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
              <div class="step-inner step03" style={{ textAlign: "center" }}>
                Frontend developer at Evy Solutions GmbH
              </div>
              <div
                style={{ position: "absolute", top: "-35px", left: "83.5%" }}
              >
                <img
                  src={require("../assets/img/impuls-logo.png")}
                  class="step step04 progress-bar-image"
                  alt="aracom logo"
                  style={{ width: "auto", height: "30px" }}
                />
              </div>
              <div class="step-inner step04" style={{ textAlign: "center" }}>
                Frontend developer at Impuls AG
              </div>
            </div>

            <div id="line">
              <div id="line-progress"></div>
            </div>
          </div>

          <div id="progress-content-section">
            <div class="section-content discovery active">
              <h2>Apprenticeship</h2>
              <h5>2019-2022</h5>
              <div
                style={{
                  textAlign: "center",
                  marginBottom: "15px",
                  fontSize: "larger",
                }}
              >
                Completed my education as software developer with{" "}
                <span style={{ color: "#2ab4cd" }}>
                  <b>89 out of 100 points</b>
                </span>
                <br />
                <span style={{ color: "#2ab4cd" }}>
                  <b>finished vocational school with: Ø 1, 2</b>
                </span>
              </div>
              <h5>
                <b>Final exam project: </b>
              </h5>
              <span style={{ fontSize: "larger" }}>
                Web application to digitally record and manage the report
                booklets of subsequent trainees <br />
                Purpose: Replace paper report booklets with digital for the
                Chamber of Industry and Commerce
              </span>{" "}
              <br />
              <br />
              <span style={{ fontSize: "larger" }}>
                <b>Technologies:</b> C# ASP.Net Core, React.JS Ant-Design,
                MongoDB
              </span>
              <div
                style={{
                  border: "2px solid rgb(198 222 227 / 64%)",
                  padding: "10px",
                  backgroundColor: "#76becdad",
                  borderRadius: "6px",
                  width: "80%",
                  margin: "0 auto",
                }}
              >
                <h5>Main Tasks:</h5>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: "40px",
                    flexWrap: "wrap",
                    textAlign: "left",
                    justifyContent: "center",
                    margin: "0 auto",
                    fontSize: "larger",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "40px",
                      flexWrap: "wrap",
                    }}
                  >
                    <span>
                      <span class="progress-content-bulletpoint">• </span>
                      Implementation of manageable timetables, training calendar
                      and editable school subjects
                    </span>
                    <span>
                      <span class="progress-content-bulletpoint">• </span>
                      Automation process for the creation of digital report
                      booklets
                    </span>
                    <span>
                      <span class="progress-content-bulletpoint">• </span>
                      Creation of apprentice dashboard
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="section-content strategy">
              <h2>
                End of apprenticeship and work as Junior Software developer at
                AraCom IT Services GmbH{" "}
              </h2>
              <h5>2022-2023</h5>
              <div>
                <p style={{ marginBottom: "0px" }}>
                  Education as <b>IT specialist for application development</b>
                  <br />
                  In 2nd year assigned to customer project in media industry
                  "Sport1" <br /> to support in web and app development
                  <br />
                </p>
              </div>
              <span style={{ fontSize: "larger" }}>
                <b>Technologies:</b> React.JS, React Native, TypeScript
              </span>
              <p>
                <div
                  style={{
                    border: "2px solid rgb(198 222 227 / 64%)",
                    backgroundColor: "#76becdad",
                    padding: "10px",
                    borderRadius: "6px",
                  }}
                >
                  <h5>Main Tasks:</h5>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "40px",
                      flexWrap: "wrap",
                      textAlign: "left",
                      justifyContent: "center",
                      margin: "0 auto",
                      fontSize: "larger",
                    }}
                  >
                    <p style={{ color: "white", marginBottom: "0px" }}>
                      <span class="progress-content-bulletpoint">• </span>
                      Implementation of new features in Web & Mobile application
                      <br />
                      <span class="progress-content-bulletpoint">• </span>
                      Improvement, bug fixing and maintenance of existing
                      components
                      <br />
                      <span class="progress-content-bulletpoint">• </span>
                      Frontend testing with <b>enzyme</b> library
                    </p>
                  </div>
                </div>
              </p>
            </div>

            <div class="section-content creative">
              <h2>Work at Evy Solutions</h2>
              <h5>2023-2024</h5>
              <p style={{ textAlign: "center" }}>
                Document and E-Mail analysis with artificial intelligence <br />
                AI-supported document processing and process automation software
                <br />
                <b>Technologies:</b> React.JS, TypeScript
              </p>
              <div
                style={{
                  border: "2px solid rgb(198 222 227 / 64%)",
                  padding: "10px",
                  borderRadius: "6px",
                  backgroundColor: "#76becdad",
                }}
              >
                <h5>Main Tasks:</h5>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: "40px",
                    flexWrap: "wrap",
                    textAlign: "left",
                    width: "90%",
                    margin: "0 auto",
                    fontSize: "larger",
                  }}
                >
                  <span>
                    <span class="progress-content-bulletpoint">• </span>{" "}
                    Implementation and creation of B2B economic web application
                    to manage business data, create requests on products for
                    customers and workflows to send the requests to the
                    specialised offices
                  </span>
                  <span style={{ color: "white" }}>
                    <span class="progress-content-bulletpoint">• </span> Working
                    on the frontend of the main product web application which
                    created AI-based trainings on documents to automate the
                    creation of e.g. from invoices{" "}
                  </span>
                  <br />
                  <span style={{ color: "white" }}>
                    <span class="progress-content-bulletpoint">• </span>
                    UI/UX Design for new features
                  </span>
                  <span style={{ color: "white" }}>
                    <span class="progress-content-bulletpoint">• </span>{" "}
                    Improvement and maintenance of existing components
                  </span>
                  <br />
                  <span style={{ color: "white" }}>
                    <span class="progress-content-bulletpoint">• </span>{" "}
                    Frontend testing with <b>playwright</b> framework
                  </span>
                </div>
              </div>
            </div>

            <div class="section-content production">
              <h2>Work at Impuls AG</h2>
              <h5>2024-today</h5>
              <p style={{ textAlign: "center" }}>
                insurance broker for private health, property, life and motor
                vehicle insurance
                <br />
                <b>Technologies:</b> ASP.Net Core, React.JS, JavaScript, Java
              </p>
              <div
                style={{
                  border: "2px solid rgb(198 222 227 / 64%)",
                  padding: "10px",
                  borderRadius: "6px",
                  width: "90%",
                  margin: "0 auto",
                  backgroundColor: "#76becdad",
                }}
              >
                <h5>Main Tasks:</h5>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: "40px",
                    flexWrap: "wrap",
                    textAlign: "left",
                    margin: "0 auto",
                    width: "80%",
                    fontSize: "larger",
                  }}
                >
                  <span style={{ color: "white" }}>
                    <span class="progress-content-bulletpoint">• </span>
                    Maintenance and further development of the main web
                    application which manages customers and their insurance
                    policies
                  </span>
                  <span style={{ color: "white" }}>
                    <span class="progress-content-bulletpoint">• </span>
                    Redesign and revision of internal web applications/tools
                  </span>
                  <span style={{ color: "white" }}>
                    <span class="progress-content-bulletpoint">• </span>
                    Expand website with new modules and scripts which is hosted
                    by HubSpot a CRM Plattform
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        className="background-image"
        src={colorSharp}
        alt="spotlight-background"
      />
    </section>
  );
};
