import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import photographyWebsiteImg from "../assets/img/Logo-luca-liam-photography.png";
import scienceprojectImg from "../assets/img/Wissenschaftspreis-project.png";
import budgetBuddyLogo from "../assets/img/budget-buddy logo 5.jpg";
import kochkisteLogo from "../assets/img/Koch Kiste project v4.jpg";
import wetterpilotLogo from "../assets/img/Wetterpilot Logo Bigger Text Round.jpg";
import dragonQuestLogo from "../assets/img/Dragon Quest Banner Website.jpg";
import "animate.css";

export const Projects = () => {
  const projects = [
    {
      title: "Photography Portfolio",
      description:
        "Website which represents my personal hobby and shows a few special shots.",
      technologies: "Technologies: React, Boostrap",
      imgUrl: photographyWebsiteImg,
      projectUrl: "https://luca-liam-photography.de",
    },
    {
      title: "Dragon Quest RPG Decision Game",
      description: `Funny Decision Game. Find out how to defeat the dragon and save the village!`,
      technologies: "Technologies: JavaScript, HTML/CSS",
      imgUrl: dragonQuestLogo,
      projectUrl: "https://dragons-quest.luca-schaefer.com",
    },
    {
      title: "STEM Science Award 2nd Place (Wissenschaftspreis MINT)",
      description: `Air Quality Monitoring with a Raspberry Pi and PM Sensor.`,
      technologies: "Technologies: JavaScript, Python, C#, HTML/CSS, Swift",
      imgUrl: scienceprojectImg,
      projectUrl:
        "https://github.com/MichaelHolley/Wissenschaftspreis-2021/tree/master",
    },
    {
      title: "Koch Kiste",
      description:
        "Recipe Search Application which is connected to a public german recipe API.",
      technologies: "Coming Soon",
      imgUrl: kochkisteLogo,
    },
    {
      title: "Wetterpilot",
      description:
        "weather application which implemented an public weather api, here I created the frontend ",
      technologies: "Coming Soon",
      imgUrl: wetterpilotLogo,
    },
    {
      title: "Budget Buddy",
      description: "A finance web app to manage your money flows",
      technologies: "Coming Soon",
      imgUrl: budgetBuddyLogo,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <div>
              <h2>Projects</h2>
              <p>
                Here are a few selected projects that I have created in my spare
                time. From web applications to CLI programmes there is a lot to
                explore. Of course there are only a few projects listed. For
                more projects and info feel free to visit my github account:{" "}
                <a
                  href="https://github.com/LiamSchaefer"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "rgb(13 240 253)",
                  }}
                >
                  https://github.com/LiamSchaefer
                </a>
              </p>
              <Tab.Container id="projects-tabs" defaultActiveKey="first">
                <Nav
                  variant="pills"
                  className="nav-pills mb-5 justify-content-center align-items-center"
                  id="pills-tab"
                >
                  <Nav.Item>
                    <Nav.Link eventKey="first">All projects</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">Budget Buddy</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">Koch Kiste</Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Row
                        style={{
                          maxWidth: "75%",
                        }}
                      >
                        {projects.map((project, index) => {
                          return <ProjectCard key={index} {...project} />;
                        })}
                      </Row>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <p>Coming Soon</p>
                  </Tab.Pane>
                  <Tab.Pane eventKey="third">
                    <p>Coming Soon</p>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
