import { Col, Container, Row } from "react-bootstrap";
import profileImage from "../assets/img/about me.jpg";
export const About = () => {
  return (
    <section className="about" id="about">
      <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <h1>About</h1>
        <Row>
          <Col
            xs={12}
            md={8}
            xl={7}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ marginRight: "20px" }}>
              <h4 style={{ color: "#6FAF61" }}>
                {"user@:~$ "}
                <span style={{ color: "#2bbbd4", fontSize: "2.8rem" }}>
                  who am I ?
                </span>
              </h4>
              <hr />
              <p
                style={{
                  lineHeight: 1.6,
                  marginTop: "30px",
                  marginBottom: "50px",
                }}
              >
                My main field of work is the{" "}
                <span style={{ color: "#2bbbd4" }}>web development</span>,
                especially working with{" "}
                <span style={{ color: "#2bbbd4" }}>Javascript and React </span>
                fuelled my passion for developing web applications.
                <br />
                <br />I feel very comfortable especially in the{" "}
                <span style={{ color: "#2bbbd4" }}>frontend development </span>
                because creativity is very important here not only in problem
                solving but also in designing and{" "}
                <span style={{ color: "#2bbbd4" }}>
                  creation of user interfaces and a good user experience
                </span>
                .
                <br />I love the process from{" "}
                <span style={{ color: "#2bbbd4" }}>
                  design to implementation
                </span>
                , but the best thing is when you see the result after hard work
                and can use it for something meaningful
                <br />
                <br />
                In my free time I like to try out and create new things and
                broaden my horizons as often as possible. One of my hobbies also
                combines both the creative and the technical:{" "}
                <span style={{ color: "#2bbbd4" }}>
                  photography and image editing
                </span>
                .
                <br />
                <br />
                <div
                  style={{
                    border: "2px solid white",
                    padding: "15px 10px 15px 15px",
                    borderRadius: "6px",
                  }}
                >
                  <p>
                    {" "}
                    ✨It always fascinates me to be able to create something new
                    with the help of data and brains and thus make life and
                    processes easier✨
                  </p>
                </div>
              </p>
            </div>
          </Col>
          <Col
            xs={12}
            md={4}
            xl={5}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <div
              style={{
                border: "4px solid white",
                borderRadius: "14px",
                display: "inline-block",
                width: "80%",
              }}
            >
              <img src={profileImage} alt="Header Img" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
